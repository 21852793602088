<template>
  <div>
    <div style="margin-bottom: 19px; font-weight: bold; font-size: 18px">
      相关文件
    </div>
    <el-table ref="multipleTable" border :data="tableData" style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column label="附件标题">
        <template slot-scope="scope">
          <div class="title" @click="previewPDF(scope.row.content)">
            {{ scope.row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="下载附件" width="100" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="clickDownload(scope.row)"
            >下载</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="preview-dialog"
      ref="preview-dialog"
      title="文件预览"
      :visible.sync="preview"
      width="1200px"
    >
      <proviewPdf :pdfUrl="pdfUrl" v-if="pdfType"></proviewPdf>
      <div class="imgbox" v-if="!pdfType"><img :src="pdfUrl" alt=""></div>
    </el-dialog>
    <permission
     v-show="dialogVisiblePermission"
     ref="permission_default"
      :dialogVisiblePermission="dialogVisiblePermission"
      @handleToggle="handleToggle"
      :typeDialog="typeDialog"
      @handlePermission="handlePermission"
    >
    </permission>
  </div>
</template>

<script>
import proviewPdf from './components/proviewPdf';
import { request } from '../../../../network';
import { setInfoData, getMemberId, getVip } from '@/assets/public/utils/token';
import permission from '@/components/pc/permission.vue';
export default {
  components: {
    proviewPdf,
    permission
  },
  data () {
    return {
      tableData: [],
      preview: false,
      pdfUrl: '',
      type: '',
      dialogVisiblePermission: false,
      typeDialog: 'Vip',
      pdfType: true
    };
  },
  created () {
    this.getRelevantFile();
  },
  mounted () {},
  methods: {
    clickDownload (rowData) {
      const that = this;
      setInfoData(request).then((resLogin) => {
        if (resLogin !== 'islogin') {
          // that.$message.error("请先登录再进行操作");
          that.dialogVisiblePermission = !that.dialogVisiblePermission;
          that.typeDialog = 'Login';
        } else {
          getVip(request).then((res) => {
            if (res !== '普通用户') {
              const fileType = rowData.content.substring(
                rowData.content.lastIndexOf('.')
              );
              request({
                method: 'POST',
                url: '/pcp/policyDetail/downRole',
                data: {
                  contentUrl: rowData.content,
                  memberId: getMemberId()
                }
              }).then((resDownRole) => {
                if (resDownRole.data.code === 200) {
                  request({
                    method: 'POST',
                    url: '/pcp/policyDetail/downOSSFileByContent',
                    responseType: 'blob',
                    data: {
                      content: rowData.content,
                      memberId: getMemberId()
                    }
                  }).then((resDownOSSFile) => {
                    if (!resDownOSSFile) {
                      return that.$message.error('下载失败');
                    } else {
                      const url = window.URL.createObjectURL(
                        new Blob([ resDownOSSFile.data ])
                      );
                      const link = document.createElement('a');
                      link.style.display = 'none';
                      link.href = url;
                      link.download = rowData.title + fileType;
                      document.body.appendChild(link);
                      link.click();
                      URL.revokeObjectURL(url);
                      that.$message.success('下载成功');
                    }
                  });
                } else {
                  that.$message({
                    message: resDownRole.data.message,
                    type: 'warning'
                  });
                }
              });
            } else {
              // that.$message.error("您还不是会员，无权限查看");
              that.dialogVisiblePermission = !that.dialogVisiblePermission;
              that.typeDialog = 'Vip';
              this.$refs.permission_default.type = 1
            }
          });
        }
      });
    },
    previewPDF (pdf) {
      const that = this;
      setInfoData(request).then((resLogin) => {
        if (resLogin !== 'islogin') {
          // that.$message.error("请先登录再进行操作");
          that.dialogVisiblePermission = !that.dialogVisiblePermission;
          that.typeDialog = 'Login';
        } else {
          getVip(request).then((res) => {
            if (res !== '普通用户') {
              if (pdf.indexOf('zip') !== -1 || pdf.indexOf('rar') !== -1) return this.$message.error('该文件为压缩包格式，请点击下载查看');
              that.preview = true;
              that.pdfUrl = pdf;
              this.pdfType = !(pdf.indexOf('png') !== -1 || pdf.indexOf('jpg') !== -1);
            } else {
              // that.$message.error("您还不是会员，无权限查看");
              that.typeDialog = 'Vip';
              that.dialogVisiblePermission = !that.dialogVisiblePermission;
            }
          });
        }
      });
    },

    async getRelevantFile () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/policyDetail/getRelevantFile',
        params: {
          id: this.$route.query.id
        }
      });
      if (res.code !== 200) return this.$message.error('获取信息失败');
      this.tableData = res.data;
    },
    handleToggle () {
      this.dialogVisiblePermission = !this.dialogVisiblePermission;
    },
    handlePermission (type) {
      if (type === 'Vip') {
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
        this.$message({
          message: '已向客服发送请求成为协会会员通知',
          type: 'success'
        });
      } else if (type === 'Login') {
        this.$router.push({ name: 'login' });
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-table {
  z-index: 0;
  .title {
    cursor: pointer;
    &:hover {
      color: #1f7ddf;
    }
  }
}
.preview-dialog {
  /deep/.el-dialog {
    margin: 0 auto !important;
    height: 100vh;
    overflow-y: scroll;
    .el-dialog__header {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }
    .el-dialog__body {
      height: calc(100vh - 120px);
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #cccccc;
    }
  }
}
.imgbox{
  width: 100%;
  height: 75vh;
  overflow: auto;
}
</style>
